import logo from "./logo.svg";
import "./App.css";
import Mailchimp from "react-mailchimp-form";
import styled from "styled-components";

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 1.5rem 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`;
function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <code><b>PandiFi</b> transforms conventional loans into decentralized assets</code>        */}
        <code>
          <b>PandiFi</b> uses blockchain technology to make consumer lending
          faster, cheaper, and more secure.
        </code>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>
            We are hard at work...<br></br>
          </code>
          <Text>
            Contact <a href="mailto:info@pandi.fi">info@pandi.fi</a> for updates
          </Text>
        </p>
      </header>
    </div>
  );
}

export default App;
